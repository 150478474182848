import { useCallback } from 'react';
import fr from '../../../translations/fr.json';

const useTranslations = () => {
  const translateInternal = useCallback((elements: any, keyChunks: string[], originalKey: string) => {
    const node = elements[keyChunks[0]];

    if (!node) {
      return `MISSING KEY '${originalKey}'`;
    }

    const [, ...rest] = keyChunks;

    if (rest.length === 0) {
      return node;
    }

    return translateInternal(node, rest, originalKey);
  }, []);

  const translate = useCallback((key: string, ...args: string[]): string => {
    if (!key) {
      throw Error('Key not specified');
    }

    const keyChunks = key.split('.');

    let translation: string = translateInternal(fr, keyChunks, key);

    if (args.length) {
      translation = args.reduce(
        (tr: string, value, index) => tr.replace(`{${index}}`, value),
        translation,
      );
    }

    return translation;
  }, []);

  return { translate };
};

export default useTranslations;
