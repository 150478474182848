import React from 'react';

import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import * as styles from './incompatibleBrower.module.scss';

const Incompatible = () => {
  const { translate } = useTranslations();

  return (
    <div>
      <p>{translate('browserError.tooOld')}</p>
      <p>
        {translate('browserError.installEdge')}
        <a
          href="https://www.microsoft.com/fr-fr/edge"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {translate('browserError.thisLink')}
        </a>
      </p>
    </div>
  );
};

export default Incompatible;
