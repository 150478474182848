import React from 'react';

import AlertModel from '@heureca/shared/models/Alert.model';

import Alert from '../Alert';

import * as styles from './alertContainer.module.scss';

interface Props {
  alerts: AlertModel[];
  removeAlert: (id: string) => void;
}

const AlertContainer = (props: Props) => {
  const { alerts, removeAlert } = props;

  return (
    <div className={styles.container}>
      {alerts.map((a) => (
        <Alert
          key={a.id}
          type={a.type}
          description={a.text}
          svgIcon={a.svgIcon}
          onRemove={() => removeAlert(a.id)}
        />
      ))}
    </div>
  );
};

export default AlertContainer;
