import React, { useEffect } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faTimes,
  faTimesCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import { AlertType } from '@heureca/shared/models/Alert.model';

import AlertCloseButton from './AlertCloseButton';

import * as styles from './alert.module.scss';
import IconAlert from './IconAlert';

interface Props {
  type: AlertType;
  description: string;
  svgIcon?: string;
  onRemove: () => void;
}

const typeMessage = {
  success: {
    message: 'Succès',
    icon: faCheckCircle,
  },
  warning: {
    message: 'Alerte',
    icon: faExclamationCircle,
  },
  error: {
    message: 'Erreur',
    icon: faTimesCircle,
  },
};

const Alert = (props: Props) => {
  const {
    type,
    description,
    onRemove,
    svgIcon,
  } = props;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onRemove();
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={classNames(styles.alert, styles[type])}>
      <IconAlert
        className={styles[type]}
        svgIcon={svgIcon}
        icon={typeMessage[type].icon}
      />
      <div className={styles.content}>
        <p className={styles.title}>{typeMessage[type].message}</p>
        <p>{description}</p>
      </div>
      <AlertCloseButton onClose={onRemove}>
        <FontAwesomeIcon icon={faTimes} />
      </AlertCloseButton>
    </div>
  );
};

export default Alert;
