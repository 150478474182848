import React, { PropsWithChildren } from 'react';

import * as styles from './alert.module.scss';

interface Props {
  onClose: () => void;
}

const AlertCloseButton = (props: PropsWithChildren<Props>) => {
  const {
    onClose,
    children,
  } = props;

  return (
    <button
      className={styles.timesButton}
      type="button"
      onClick={onClose}
    >
      {children}
    </button>
  );
};

export default AlertCloseButton;
