import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';

import Alert, { AlertType } from '@heureca/shared/models/Alert.model';
import AlertContainer from '@heureca/shared/components/AlertContainer';
import randomString from '@heureca/shared/utils/strings.utils';

interface AlertContextModel {
  addAlert: (type: AlertType, text: string, svgIcon?: string) => void;
}

export const AlertContext = createContext<AlertContextModel>({
  addAlert: () => { },
});

const AlertContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const addAlert = useCallback((type: AlertType, text: string, svgIcon?: string) => {
    setAlerts([...alerts, {
      id: randomString(),
      text,
      type,
      svgIcon,
    }]);
  }, [alerts, setAlerts]);

  const removeAlert = useCallback((id: string) => {
    setAlerts(alerts.filter((a) => a.id !== id));
  }, [alerts, setAlerts]);

  return (
    <AlertContext.Provider value={{ addAlert }}>
      <AlertContainer
        alerts={alerts}
        removeAlert={removeAlert}
      />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
