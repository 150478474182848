/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import IncompatibleBrowser from './src/modules/shared/components/IncompatibleBrower';

import AlertContextProvider from './src/modules/app/AlertContext';

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element }) => {

  const isIE = navigator.userAgent.indexOf('MSIE') !== -1
    || navigator.appVersion.indexOf('Trident/') > -1;

  if (isIE) {
    return <IncompatibleBrowser />;
  }

  return (
    <AlertContextProvider>
      {element}
    </AlertContextProvider>
  )
};
