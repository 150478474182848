// extracted by mini-css-extract-plugin
export var icon = "alert-module--icon--20ZyJ";
export var success = "alert-module--success--2UmI-";
export var error = "alert-module--error--arcwn";
export var warning = "alert-module--warning--1mFgz";
export var timesButton = "alert-module--timesButton--2P5X7";
export var svgContainer = "alert-module--svgContainer--2TnQ4";
export var customIcon = "alert-module--customIcon--1KNtA";
export var alert = "alert-module--alert--1Zef2";
export var slideLeft = "alert-module--slide-left--1eQwg";
export var content = "alert-module--content--WpAqa";
export var title = "alert-module--title--1JViT";