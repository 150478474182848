import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import * as styles from './alert.module.scss';

interface Props {
  icon: IconProp;
  svgIcon?: string;
  className: string;
}

const IconAlert = (props: Props) => {
  const { icon, svgIcon, className } = props;

  if (!svgIcon) {
    return (
      <FontAwesomeIcon
        icon={icon}
        className={classNames(styles.icon, className)}
      />
    );
  }

  return (
    <div className={styles.svgContainer}>
      <img
        src={svgIcon}
        alt="Illustration"
        className={styles.customIcon}
      />
    </div>
  );
};

export default IconAlert;
